/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AnyAction } from 'redux'

const actions = {
  LOAD_DOCUMENTS: 'documents/LOAD_DOCUMENTS',
  SET_STATE: 'documents/SET_STATE',
  LOAD_STATE: 'documents/LOAD_STATE',
  GET_STATE: 'documents/GET_STATE',
}
export default actions

export const setLoading = (loading: boolean): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: {
      loading,
    },
  }
}

export const loadDocuments = (sessionId: string, projectId: string): AnyAction => {
  return {
    type: actions.LOAD_DOCUMENTS,
    payload: {
      sessionId,
      projectId,
    },
  }
}

export const setState = (payload: any): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload,
  }
}
