/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import { FacebookFilled, InstagramFilled } from '@ant-design/icons'
import { Icon as LegacyIcon } from '@ant-design/compatible'

const SocialProfileSelectValue = (props: any): JSX.Element => {
  const { data } = props

  return (
    <div>
      {data.type === 'INSTAGRAMBUSINESS' && <InstagramFilled />}
      {data.type === 'FACEBOOKPAGE' && <FacebookFilled />}
      {data.type === 'TWITTER' && <LegacyIcon type="twitter" theme="filled" />}
      {data.socialNetworkUsername}
    </div>
  )
}

export default SocialProfileSelectValue
