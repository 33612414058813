/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import { FacebookFilled, InstagramFilled, TwitterOutlined } from '@ant-design/icons'
// import {components} from 'react-select';

const SocialProfileSelectMultiValueLabel = (props: any): JSX.Element => {
  const { data } = props

  return (
    <div style={{ padding: 2 }}>
      <div style={{ float: 'left', marginRight: 8 }}>
        {data.type === 'INSTAGRAMBUSINESS' && <InstagramFilled style={{ width: 18, height: 18 }} />}
        {data.type === 'FACEBOOKPAGE' && <FacebookFilled style={{ width: 18, height: 18 }} />}
        {data.type === 'TWITTER' && <TwitterOutlined style={{ width: 18, height: 18 }} />}
      </div>
      <div style={{ float: 'left' }}>
        <span style={{ whiteSpace: 'nowrap' }}>{data.socialNetworkUsername}</span>
      </div>
    </div>
  )
}

export default SocialProfileSelectMultiValueLabel
