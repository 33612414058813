/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { all, put, takeEvery } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { WorkfrontDocumentList } from '@antegma/accallio-communication-suite-hootsuite-publishing-api-javacript-sdk'
import documentApi from '~/services/Document'
import actions, { setLoading } from './actions'

function* LOAD_DOCUMENTS({ payload }: AnyAction) {
  yield put(setLoading(true))

  const result = yield documentApi.getDocuments(payload.sessionId, payload.projectId)
  const documentList: WorkfrontDocumentList = result.data

  yield put({
    type: actions.SET_STATE,
    payload: {
      documents: documentList.result,
      loading: false,
    },
  })
}

function* LOAD_STATE() {
  // const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.settings.user?.access_token }))

  yield put(setLoading(true))

  yield put(setLoading(false))
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_STATE, LOAD_STATE), takeEvery(actions.LOAD_DOCUMENTS, LOAD_DOCUMENTS)])
}
