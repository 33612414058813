/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'

interface IProps {
  currentCharCount: number
  maxCount: number
}
const Counter: React.FunctionComponent<IProps> = ({ currentCharCount, maxCount }: IProps) => {
  return (
    <>
      {currentCharCount <= maxCount ? (
        <span>{maxCount - currentCharCount}</span>
      ) : (
        <span style={{ color: '#FF0000' }}>{maxCount - currentCharCount}</span>
      )}
    </>
  )
}

export default Counter
