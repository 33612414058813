/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AnyAction } from 'redux'
import { Content } from '@antegma/accallio-communication-suite-hootsuite-publishing-api-javacript-sdk'

const actions = {
  LOAD_SETTINGS: 'libraries/LOAD_SETTINGS',
  SAVE_SETTINGS: 'libraries/SAVE_SETTINGS',
  SET_STATE: 'libraries/SET_STATE',
  LOAD_STATE: 'libraries/LOAD_STATE',
  GET_STATE: 'libraries/GET_STATE',
}
export default actions

export const setLoading = (loading: boolean): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: {
      loading,
    },
  }
}

export const loadSettings = (sessionId: string, projectId: string, userId: string): AnyAction => {
  return {
    type: actions.LOAD_SETTINGS,
    payload: {
      sessionId,
      projectId,
      userId,
    },
  }
}

export const saveSettings = (sessionId: string, projectId: string, content: Content): AnyAction => {
  return {
    type: actions.SAVE_SETTINGS,
    payload: {
      sessionId,
      projectId,
      content,
    },
  }
}

export const setState = (payload: any): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload,
  }
}
