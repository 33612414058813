import { WorkfrontDocument } from '@antegma/accallio-communication-suite-hootsuite-publishing-api-javacript-sdk'
import { AnyAction } from 'redux'
import actions from './actions'

export type TDocumentsState = {
  loading: boolean
  documents: WorkfrontDocument[]
  documentLoaded: boolean
}
const initialState = {
  loading: false,
  documents: [],
  documentLoaded: false,
}
export default function documentsReducer(state: TDocumentsState = initialState, action: AnyAction): TDocumentsState {
  switch (action.type) {
    case actions.SET_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case actions.GET_STATE:
      return state
    default:
      return state
  }
}
