/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/no-danger */
import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Settings, SocialProfile, WorkfrontDocument, Content } from '@antegma/accallio-communication-suite-hootsuite-publishing-api-javacript-sdk'

import { DeleteOutlined, EditOutlined, FacebookFilled, InstagramFilled, LinkedinFilled, SmileOutlined, TwitterOutlined } from '@ant-design/icons'

import { Form, PageHeader, Button, Row, Col, Divider, Layout, Input, Card, TimePicker, DatePicker, Radio, Modal, Table } from 'antd'
import '@ant-design/compatible/assets/index.css'

import Axios, { AxiosResponse } from 'axios'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/adobeworkfront/redux/interface'
import { Picker } from 'emoji-mart'
import moment, { Moment } from 'moment'
import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import * as workfrontActions from '@antegma/accallio-cloud-react-components/lib/adobeworkfront/redux/workfront/actions'
import queryString from 'query-string'
import { PhotoEditor } from '~/components/photoeditor'
import settingsApi from '~/services/Settings'
import { ISagas } from '~/redux/interface'
import actions, { loadSettings, saveSettings } from '~/redux/content/actions'
import { loadDocuments } from '~/redux/document/actions'
import Counter from '~/components/counter'
import Preview from '~/components/preview'
import { MediaUrl, Message, Post } from '~/components/preview/data'
import style from './index.module.scss'
import SocialProfileSelectOption from './SocialProfileSelectOption'
import SocialProfileSelectValue from './SocialProfileSelectValue'
import SocialProfileSelectMultiValueLabel from './SocialProfileSelectMultiValueLabel'

const { Footer } = Layout
const { Column } = Table
const { Search } = Input
const { Meta } = Card
const { Option } = components

interface LabelValue {
  label: string
  value: string
}

interface ShortLink {
  originalUrl: string
  url: string
}

const Libraries: React.FunctionComponent = () => {
  const librariesState = useSelector((state: ISagas) => state.libraries)
  const documentsState = useSelector((state: ISagas) => state.documents)
  const dispatch = useDispatch()
  const contentInput = useRef(null)
  const twitterMaxChars = 280
  const facebookMaxChars = 2200
  const instagramMaxChars = 2200
  const linkedinMaxChars = 3000
  const timeFormat = 'HH:mm'
  const dateFormat = 'DD.MM.YYYY HH:mm'
  const publishingScheduled = 'scheduled'
  const publishingInstant = 'instant'
  const [posts, setPosts] = useState<Post[]>([])
  const [twitterEnabled, setTwitterEnabled] = useState<boolean>()
  const [facebookEnabled, setFacebookEnabled] = useState<boolean>()
  const [instagramEnabled, setInstagramEnabled] = useState<boolean>()
  const [linkedinEnabled, setLinkedinEnabled] = useState<boolean>()
  const [currentCharCount, setCurrentCharCount] = useState<number>(0)
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false)
  const [showDocumentSelectorModal, setShowDocumentSelectorModal] = useState<boolean>(false)
  const [showPhotoEditorModal, setShowPhotoEditorModal] = useState<boolean>(false)
  const [publishingType, setPublishingType] = useState<string>(publishingScheduled)
  const [selectedSocialProfiles, setSelectedSocialProfiles] = useState<SocialProfile[]>([])
  const [selectedDocuments, setSelectedDocuments] = useState<WorkfrontDocument[]>([])
  const [text, setText] = useState<string>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<[]>([])
  const [selectedTags, setSelectedTags] = useState<LabelValue[]>([])
  const [selectedPublishingDate, setSelectedPublishingDate] = useState<Moment>()
  const tagsOptions: LabelValue[] = [
    { value: 'mercedesbenz', label: 'mercedesbenz' },
    { value: 'amg', label: 'amg' },
    { value: 'daimler', label: 'daimler' },
  ]

  const params = queryString.parse(window.location.search)

  let sessionId = ''
  let objectId = ''
  let userId = ''

  if (typeof params.sessionId === 'string') {
    sessionId = params.sessionId
  }
  if (typeof params.objectId === 'string') {
    objectId = params.objectId
  }
  if (typeof params.userId === 'string') {
    userId = params.userId
  }

  useEffect(() => {
    dispatch({
      type: workfrontActions.default.SET_PARAMETERS,
      payload: {
        sessionId,
        objectId,
        userId,
      },
    })
    dispatch(loadSettings(sessionId, objectId, userId))
    dispatch(loadDocuments(sessionId, objectId))
  }, [])

  useEffect(() => {
    if (librariesState.loading === false) {
      if (librariesState.settings && librariesState.settings.content) {
        if (librariesState.settings.content.selectedSocialProfiles) {
          setSelectedSocialProfiles(librariesState.settings?.content?.selectedSocialProfiles)

          const a = librariesState.settings?.content?.selectedSocialProfiles.map((socialProfile) => {
            if (socialProfile.type === 'FACEBOOKPAGE') {
              setFacebookEnabled(true)
            } else if (socialProfile.type === 'TWITTER') {
              setTwitterEnabled(true)
            } else if (socialProfile.type === 'LINKEDINPAGES') {
              setLinkedinEnabled(true)
            } else if (socialProfile.type === 'INSTAGRAMBUSINESS') {
              setInstagramEnabled(true)
            }
            return true
          })
        }
        if (librariesState.settings.content.selectedMedia) {
          setSelectedDocuments(librariesState.settings?.content?.selectedMedia)
        }
        if (librariesState.settings.content.publishingDate) {
          setSelectedPublishingDate(moment(librariesState.settings?.content?.publishingDate))
        }
        if (librariesState.settings.content.text) {
          setText(librariesState.settings?.content?.text)
        }
        if (librariesState.settings.content.tags) {
          // setSelectedTags(tags)
        }
        if (librariesState.settings.content.selectedSocialProfiles && librariesState.settings.content.text) {
          if (librariesState.settings.content.selectedMedia) {
            updatePreview(
              librariesState.settings.content.selectedSocialProfiles,
              librariesState.settings.content.text,
              librariesState.settings.content.selectedMedia,
            )
          } else {
            updatePreview(librariesState.settings.content.selectedSocialProfiles, librariesState.settings.content.text, selectedDocuments)
          }
        }
      }
    }

    console.log(`Selected Document Count ${selectedDocuments.length}`)
  }, [librariesState.loading])

  const onContentChange = (value): void => {
    if (contentInput.current !== undefined) {
      const input = contentInput.current as unknown as HTMLElement
      const content = input.innerHTML
      setText(content)
      console.log(content)
      const count = content.length
      setCurrentCharCount(count)
      if (selectedSocialProfiles) {
        updatePreview(selectedSocialProfiles, content, selectedDocuments)
      }
    }
  }

  const submitHandler = (e): void => {
    e.preventDefault()
  }

  const onChangePublishingType = (e): void => {
    console.log('radio checked', e.target.value)
    setPublishingType(e.target.value)
  }

  const FacebookCounter = (): JSX.Element => {
    return (
      <>
        {facebookEnabled === true ? (
          <div style={{ paddingLeft: 8, float: 'left' }}>
            <FacebookFilled style={{ fontSize: '18px', paddingRight: 2, color: '#4267B2' }} />
            <Counter currentCharCount={currentCharCount} maxCount={facebookMaxChars} />
          </div>
        ) : null}
      </>
    )
  }

  const TwitterCounter = (): JSX.Element => {
    return (
      <>
        {twitterEnabled === true ? (
          <div style={{ paddingLeft: 8, float: 'left' }}>
            <TwitterOutlined style={{ fontSize: '18px', paddingRight: 2, color: '#1DA1F2' }} />
            <Counter currentCharCount={currentCharCount} maxCount={twitterMaxChars} />
          </div>
        ) : null}
      </>
    )
  }

  const InstagramCounter = (): JSX.Element => {
    return (
      <>
        {instagramEnabled === true ? (
          <div style={{ paddingLeft: 8, float: 'left' }}>
            <InstagramFilled style={{ fontSize: '18px', paddingRight: 2, color: '#C13584' }} />
            <Counter currentCharCount={currentCharCount} maxCount={instagramMaxChars} />
          </div>
        ) : null}
      </>
    )
  }

  const LinkedinCounter = (): JSX.Element => {
    return (
      <>
        {linkedinEnabled === true ? (
          <div style={{ paddingLeft: 8, float: 'left' }}>
            <LinkedinFilled style={{ fontSize: '18px', paddingRight: 2, color: '#2867B2' }} />
            <Counter currentCharCount={currentCharCount} maxCount={linkedinMaxChars} />
          </div>
        ) : null}
      </>
    )
  }

  const changeShowEmojiPicker = (e): void => {
    console.log('picker')
    if (showEmojiPicker) {
      setShowEmojiPicker(false)
    } else {
      setShowEmojiPicker(true)
    }
  }

  const onEmojiSelected = (e): void => {
    const newText = `${text}${e.native}`
    if (contentInput.current !== undefined) {
      const input = contentInput.current as unknown as HTMLElement
      const content = input.innerHTML
      input.innerHTML = newText
      setText(newText)
      if (selectedSocialProfiles) {
        updatePreview(selectedSocialProfiles, content, selectedDocuments)
      }
    }

    setShowEmojiPicker(false)
  }

  const handleChangeTags = (tags): void => {
    setSelectedTags(tags)
  }

  const changeSocialProfiles = (socialProfiles): void => {
    setSelectedSocialProfiles(socialProfiles)
    setFacebookEnabled(false)
    setInstagramEnabled(false)
    setLinkedinEnabled(false)
    setTwitterEnabled(false)

    socialProfiles.map((socialProfile) => {
      if (socialProfile.type === 'FACEBOOKPAGE') {
        setFacebookEnabled(true)
      } else if (socialProfile.type === 'TWITTER') {
        setTwitterEnabled(true)
      } else if (socialProfile.type === 'LINKEDINPAGES') {
        setLinkedinEnabled(true)
      } else if (socialProfile.type === 'INSTAGRAMBUSINESS') {
        setInstagramEnabled(true)
      }
      return true
    })
    if (text) {
      updatePreview(socialProfiles, text, selectedDocuments)
    }
  }

  const urlify = (t, socialNetworkType, socialProfileId): string => {
    const urlRegex = /(https?:\/\/[^\s]+)/g
    return t.replace(urlRegex, (url): void => {
      const result = settingsApi.shortenLink(sessionId, objectId, socialNetworkType, socialProfileId, url).then((response) => {
        if (response.data.link !== undefined) {
          return `<a href="${response.data.link}">${response.data.link}</a>`
        }
        return url
      })
    })
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  }

  const updatePreview = (s: SocialProfile[], t: string, d: WorkfrontDocument[]): void => {
    const p: Post[] = []
    if (t !== undefined) {
      if (s !== undefined) {
        s.map((socialProfile) => {
          const formattedText = urlify(t, socialProfile.type, socialProfile.socialNetworkId)
          const message: Message = {
            text: formattedText,
          }

          if (d) {
            message.mediaUrls = d.map((selectedDocument) => {
              const mediaUrl: MediaUrl = {}
              if (selectedDocument.downloadUrl) {
                mediaUrl.url = selectedDocument.downloadUrl
              }
              return mediaUrl
            })
          }

          const post: Post = {
            socialProfile,
            message,
          }
          p.push(post)
          return true
        })
      }
    }
    setPosts(p)
  }

  const openDocumentSelector = (): void => {
    setShowDocumentSelectorModal(true)
  }

  const handleDocumentSelectorSubmit = (): void => {
    /* this.setState({
      ModalText: 'The modal will be closed after two seconds',
      confirmLoading: true,
    }); */

    documentsState.documents.filter((n) => {
      if (n.id !== undefined) {
        const included = selectedRowKeys.includes(n.id as never)
        if (included) {
          setSelectedDocuments((oldArray) => [...oldArray, n])
        }
        return included
      }
      return false
    })
    setSelectedRowKeys([])

    setTimeout(() => {
      setShowDocumentSelectorModal(false)
      if (selectedSocialProfiles && text) {
        updatePreview(selectedSocialProfiles, text, selectedDocuments)
      }
    }, 500)
  }

  const handleDocumentSelectorCancel = (): void => {
    console.log('Clicked cancel button')
    setShowDocumentSelectorModal(false)
  }

  const openPhotoEditor = (): void => {
    setShowPhotoEditorModal(true)
  }

  const removeDocument = (document, index): void => {
    const newList = selectedDocuments.filter((item) => item.id !== document.id)
    setSelectedDocuments(newList)
    if (selectedSocialProfiles && text) {
      updatePreview(selectedSocialProfiles, text, newList)
    }
  }

  const handlePhotoEditorSubmit = (): void => {
    /* this.setState({
      ModalText: 'The modal will be closed after two seconds',
      confirmLoading: true,
    }); */
    setTimeout(() => {
      setShowPhotoEditorModal(false)
    }, 500)
  }

  const handlePhotoEditorCancel = (): void => {
    console.log('Clicked cancel button')
    setShowPhotoEditorModal(false)
  }

  const onSelectChange = (s): void => {
    console.log('selectedRowKeys changed: ', s)
    setSelectedRowKeys(s)
  }

  const onChangePublishingDate = (d): void => {
    setSelectedPublishingDate(d)
  }

  const updatePost = (): void => {
    const content: Content = {}
    content.text = text
    content.selectedMedia = selectedDocuments
    content.selectedSocialProfiles = selectedSocialProfiles
    content.tags = selectedTags.map((tag) => {
      return tag.value
    })
    content.publishingDate = moment(selectedPublishingDate).toISOString()

    dispatch(saveSettings(sessionId, objectId, content))
  }

  return (
    <div style={{ width: '100%' }}>
      <div style={{ width: '30%', minWidth: 420, minHeight: 780, maxHeight: 780, float: 'left' }}>
        <div style={{ padding: 20, width: '100%', minWidth: 420, minHeight: 780, maxHeight: 780, overflow: 'auto' }}>
          <Row gutter={[24, 24]} className={style.contentDetail}>
            <Col>
              <label style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Social Networks</label>
            </Col>
          </Row>
          <Row gutter={[24, 24]} className={style.contentDetail}>
            <Col span={24}>
              <Select
                isMulti
                getOptionLabel={(option): string => option.socialNetworkUsername}
                getOptionValue={(option): string => option.id}
                closeMenuOnSelect={false}
                onChange={changeSocialProfiles}
                options={librariesState.settings?.socialProfiles}
                value={selectedSocialProfiles}
                components={{
                  Option: SocialProfileSelectOption,
                  SingleValue: SocialProfileSelectValue,
                  MultiValueLabel: SocialProfileSelectMultiValueLabel,
                }}
              />
            </Col>
          </Row>
          <Row gutter={[24, 24]} className={style.contentDetail}>
            <Col span={12}>
              <label style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Content</label>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <div style={{ float: 'right' }}>
                <FacebookCounter />
                <TwitterCounter />
                <InstagramCounter />
                <LinkedinCounter />
              </div>
            </Col>
          </Row>
          <Row gutter={[24, 24]} className={style.contentDetail}>
            <Col span={24}>
              <div
                ref={contentInput}
                contentEditable="true"
                spellCheck="true"
                onKeyUp={onContentChange}
                style={{ border: '1px solid #e4e9f0', padding: 5, borderRadius: 4, height: 180, maxHeight: 180, overflow: 'auto' }}
                suppressContentEditableWarning={true}
                dangerouslySetInnerHTML={{
                  __html:
                    librariesState.settings !== undefined && librariesState.settings.content && librariesState.settings.content.text
                      ? librariesState.settings.content.text
                      : '',
                }}
              />
              <SmileOutlined style={{ fontSize: '20px' }} onClick={changeShowEmojiPicker} />
              {showEmojiPicker === true ? (
                <Picker
                  native={true}
                  onSelect={onEmojiSelected}
                  style={{ position: 'absolute', top: '175px', left: '20px', zIndex: 1000 }}
                  showPreview={false}
                />
              ) : null}
            </Col>
          </Row>
          <Row gutter={[24, 24]} className={style.contentDetail}>
            <Col>
              <label style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Media</label>
            </Col>
          </Row>
          <Row gutter={[24, 24]} className={style.contentDetail}>
            <Col span={24}>
              <div
                style={{
                  backgroundColor: 'rgb(244,244,244)',
                  borderStyle: '1px solid',
                  borderColor: 'rgb(224,225,227)',
                  width: '100%',
                  minHeight: 50,
                  padding: 8,
                }}
              >
                {selectedDocuments === undefined && <div>No media files selected</div>}
                {selectedDocuments?.map((document, index) => (
                  <Card
                    key={document.id}
                    style={{ width: '100%', marginTop: 14 }}
                    actions={[
                      <EditOutlined key="edit" onClick={openPhotoEditor} />,
                      <DeleteOutlined key="delete" onClick={(event): void => removeDocument(document, index)} />,
                    ]}
                  >
                    <Meta
                      avatar={<img alt="" style={{ width: 100 }} src={document.previewUrl} />}
                      title={`${document.name}${document.type === 'JPG Image' ? '.jpg' : '.png'}`}
                      description={`${document.size}b`}
                    />
                  </Card>
                ))}
              </div>
              <Button onClick={openDocumentSelector} style={{ width: '100%' }} size="large" type="dashed">
                Add Media
              </Button>
            </Col>
          </Row>
          <Row gutter={[24, 24]} className={style.contentDetail}>
            <Col>
              <label style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Tags</label>
            </Col>
          </Row>
          <Row gutter={[24, 24]} className={style.contentDetail}>
            <Col span={24}>
              <CreatableSelect isMulti value={selectedTags} onChange={handleChangeTags} options={tagsOptions} />
            </Col>
          </Row>
          <Row gutter={[24, 24]} className={style.contentDetail}>
            <Col span={24}>
              <label style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Publishing Settings</label>
            </Col>
          </Row>
          <Row gutter={[24, 24]} className={style.contentDetail}>
            <Col span={24}>
              <Radio.Group onChange={onChangePublishingType} value={publishingType}>
                <Radio value={publishingScheduled}>Schedule</Radio>
                <Radio value={publishingInstant}>Now</Radio>
              </Radio.Group>
              <DatePicker
                value={selectedPublishingDate}
                onChange={onChangePublishingDate}
                showTime={{ format: timeFormat }}
                placeholder="Select Publishing Date"
                format={dateFormat}
                style={{ width: '100%' }}
                disabled={publishingType === publishingInstant}
              />
            </Col>
          </Row>
          {twitterEnabled === true && (
            <div className={style.contentDetail}>
              <Row gutter={[24, 24]} className={style.contentDetail}>
                <Col span={24}>
                  <label style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Twitter Location</label>
                </Col>
              </Row>
              <Row gutter={[24, 24]} className={style.contentDetail}>
                <Col span={24}>
                  <Select />
                </Col>
              </Row>
            </div>
          )}
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <div style={{ height: 80 }} />
            </Col>
          </Row>
        </div>
        <div
          style={{
            position: 'absolute',
            top: 700,
            left: 0,
            height: 80,
            width: '30%',
            padding: 20,
            backgroundColor: 'rgb(244,244,244)',
            borderTopStyle: 'solid',
            borderTopWidth: 1,
            borderTopColor: 'rgb(224,225,227)',
            zIndex: 1000,
          }}
        >
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Button onClick={updatePost} type="primary" style={{ width: '100%' }} size="large">
                Save Draft
              </Button>
            </Col>
            <Col span={12}>
              <Button onClick={updatePost} type="primary" style={{ width: '100%' }} size="large">
                {publishingType === publishingInstant ? 'Publish' : 'Schedule'}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <div style={{ width: '70%', height: '100%', minHeight: 780, maxHeight: 780, overflow: 'auto', backgroundColor: '#2f3538', float: 'left' }}>
        <Preview posts={posts} />
      </div>
      <div style={{ clear: 'both' }} />
      <Modal
        title="Edit Image"
        visible={showPhotoEditorModal}
        onOk={handlePhotoEditorSubmit}
        onCancel={handlePhotoEditorCancel}
        confirmLoading={documentsState.documentLoaded}
      >
        <div />
      </Modal>
      <div style={{ display: 'none' }}>
        <PhotoEditor imageUrl="https://antegmawebsite.blob.core.windows.net/website/wp-content/uploads/2021/04/AdobeStock_291727479-768x339.jpeg" />
      </div>
      <Modal
        title="Documents"
        bodyStyle={{ padding: 0 }}
        width={650}
        visible={showDocumentSelectorModal}
        onOk={handleDocumentSelectorSubmit}
        onCancel={handleDocumentSelectorCancel}
        confirmLoading={documentsState.documentLoaded}
      >
        <Table dataSource={documentsState.documents} rowSelection={{ selectedRowKeys, onChange: onSelectChange }} rowKey="id">
          <Column
            title="Preview"
            key="previewUrl"
            render={(t, record: WorkfrontDocument): JSX.Element => <img src={record.previewUrl} style={{ width: 120 }} alt="" />}
          />
          <Column
            title="Name"
            dataIndex="name"
            render={(t, record: WorkfrontDocument): JSX.Element => (
              <div>
                <span>
                  {record.name}
                  {record.type === 'JPG Image' ? '.jpg' : '.png'}
                </span>
                <br />
                <span style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '15px' }}>{record.size}b</span>
              </div>
            )}
          />
        </Table>
      </Modal>
    </div>
  )
}

export default {
  name: 'Libraries',
  routeProps: {
    path: '/libraries',
    component: Libraries,
  },
  navProps: {
    title: 'Libraries ',
  },
}
