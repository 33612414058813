// import { EActivityType, ESocialNetworkType } from "Services/api/activity-stream/enum";
const en = {
  'Selected ReportingSuite': 'Selected ReportingSuite',
  None: 'None',
  Submit: 'Submit',
  'Please input a API Key!': 'Please input a API Key!',
  'Please input a Secret!': 'Please input a Secret!',
  Settings: 'Settings',
}
export default en
