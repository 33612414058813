/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import { FacebookOutlined, InstagramOutlined, TwitterOutlined } from '@ant-design/icons'
import { Avatar } from 'antd'
import { components } from 'react-select'

const { Option } = components

const SocialProfileSelectOption = (props: any): JSX.Element => {
  const { data } = props
  return (
    <Option {...props}>
      <div style={{ width: 32, height: 32, float: 'left', display: 'block', position: 'relative' }}>
        <Avatar src={data.avatarUrl} />
        {data.type === 'INSTAGRAMBUSINESS' && (
          <Avatar
            icon={<InstagramOutlined />}
            size={18}
            style={{ position: 'absolute', width: 18, height: 18, top: 14, left: 14, zIndex: 9999, backgroundColor: '#C13584' }}
          />
        )}
        {data.type === 'FACEBOOKPAGE' && (
          <Avatar
            icon={<FacebookOutlined />}
            size={18}
            style={{ position: 'absolute', width: 18, height: 18, top: 14, left: 14, zIndex: 9999, backgroundColor: '#4267B2' }}
          />
        )}
        {data.type === 'TWITTER' && (
          <Avatar
            icon={<TwitterOutlined />}
            size={18}
            style={{ position: 'absolute', width: 18, height: 18, top: 14, left: 14, zIndex: 9999, backgroundColor: '#1DA1F2' }}
          />
        )}
      </div>
      <div style={{ float: 'left', lineHeight: '32px', verticalAlign: 'middle', marginLeft: 10 }}>{data.socialNetworkUsername}</div>
      <div style={{ clear: 'both' }} />
    </Option>
  )
}

export default SocialProfileSelectOption
