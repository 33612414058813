/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-danger */
import React from 'react'
import DOMPurify from 'dompurify'
import { FacebookFilled } from '@ant-design/icons'
import style from './index.module.scss'
import { Post } from '../data'

interface IProps {
  post: Post
}

const FacebookPreview: React.FunctionComponent<IProps> = ({ post }: IProps): JSX.Element => {
  return (
    <div className="message">
      <div className="header">
        <FacebookFilled style={{ fontSize: '24px', paddingRight: 2, color: '#4267B2' }} />
        <span>Facebook</span>
      </div>
      <div className="body">
        <div className="facebook">
          <div className="header">
            <div className="image">
              <div className="rc-SocialNetworkAvatar -avatar" style={{ width: 40, height: 40 }}>
                <div className="-imageContainer" style={{ width: 40, height: 40 }}>
                  <img
                    alt={post.socialProfile.socialNetworkUsername}
                    className="-avatar x-centered"
                    src={post.socialProfile.avatarUrl}
                    style={{ height: 40 }}
                  />
                </div>
              </div>
            </div>
            <div className="-right">
              <div className="-name">
                <span>{post.socialProfile.socialNetworkUsername}</span>
                {post.place && (
                  <span>
                    {post.place}
                    <span className="locationPreamble"> is at </span>
                    <span className="-locationText">
                      <svg className="sc-jXNtHH fAVYOW" width="16" height="16" viewBox="0 0 20 20" fill="#365899">
                        <path d="M9.98634945,0 C5.11895476,0 1.25,3.86895476 1.25,8.73634945 C1.25,11.1076443 2.12363495,13.3541342 3.87090484,14.9765991 C3.99570983,15.1014041 8.98790952,19.5943838 9.11271451,19.7191888 C9.61193448,20.0936037 10.3607644,20.0936037 10.7351794,19.7191888 C10.8599844,19.5943838 15.9769891,15.1014041 15.9769891,14.9765991 C17.724259,13.3541342 18.5978939,11.1076443 18.5978939,8.73634945 C18.7226989,3.86895476 14.8537441,0 9.98634945,0 Z M9.98634945,11.2324493 C8.61349454,11.2324493 7.49024961,10.1092044 7.49024961,8.73634945 C7.49024961,7.36349454 8.61349454,6.24024961 9.98634945,6.24024961 C11.3592044,6.24024961 12.4824493,7.36349454 12.4824493,8.73634945 C12.4824493,10.1092044 11.3592044,11.2324493 9.98634945,11.2324493 Z" />
                      </svg>
                      ${post.place.name}
                    </span>
                  </span>
                )}
              </div>
              <div className="-timeStamp">Just now</div>
            </div>
          </div>
          <div className="-content">
            <div className="-message" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.message.text) }} />
            {post.message.mediaUrls?.map((mediaUrl, index) => (
              <div className="-media">
                <div className="-oneImageMode">
                  <div className="-image0 -landscape">
                    <img alt="" src={mediaUrl.url} style={{ width: '100%' }} />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="-footer">
            <hr />
            <div className="-actions">
              <span className="-facebookAction">
                <span className="-like -icon">
                  <span className="rc-IconBase x-StandardIcon x-svgIcon">
                    <svg viewBox="0 0 20 20" style={{ height: 16, width: 20, fill: 'rgb(148, 154, 155)' }}>
                      <path d="M4.2,18.3 C4.2,18.9 4,19.3 3.4,19.3 C2.7,19.3 2,19.3 1.3,19.3 C0.7,19.3 0.4,18.9 0.4,18.3 L0.4,11.3 C0.4,10.7 0.7,10.3 1.3,10.3 L3.3,10.3 C3.9,10.3 4.2,10.6 4.2,11.3 L4.2,18.3 Z M11.0613035,19.5926175 C9.07535481,19.5410612 7.01294189,19.1112634 5.272426,18.7 L5.272426,10.7 C6.10885527,10.712785 6.41287509,10.5023682 7.072426,9.5 C7.73197691,8.49763181 8.172426,7.6 8.572426,6.7 C8.872426,6 9.172426,5.6 9.772426,5.2 C10.372426,4.8 10.772426,4.2 10.972426,3.6 C11.272426,2.6 11.372426,1.7 11.472426,0.8 C11.472426,0.6 11.5321286,0.431520272 11.772426,0.3 C12.0127234,0.168479728 13.4564914,0.481929844 13.8807549,1.54971856 C14.3050185,2.61750727 14.275527,3.92609696 13.7685773,5.04536706 C13.2616276,6.16463716 13.2324088,5.99917784 12.9661359,6.7 C12.6998629,7.40082216 12.5727359,8.00613643 13.019478,8.00613643 C13.319478,8.00613643 16.272426,8 17.672426,8.1 C18.841157,8.1 19.6106952,9.40337821 19.6106952,10.1033782 C19.6106952,10.8033782 19.0009316,10.8664011 19.0068236,11.5398224 C19.0127157,12.2132436 19.56402,12.3045566 19.566195,12.9579622 C19.5683701,13.6113678 18.7784343,13.6879556 18.7643564,14.3296127 C18.7502784,14.9712698 19.1632626,14.8938055 19.1532311,15.7162701 C19.1431995,16.5387348 18.4263444,16.5042615 18.1884542,17.0582637 C17.950564,17.612266 18.0541434,17.6407805 18.0541434,18.1799621 C18.0541434,18.7191437 16.8943475,19.9885606 14.9996338,19.9429779 C13.1049201,19.8973952 13.0472523,19.6441738 11.0613035,19.5926175 Z" />
                    </svg>
                  </span>
                </span>
                <span className="-text">Like</span>
              </span>
              <span className="-facebookAction">
                <span className="-comment -icon">
                  <span className="rc-IconBase x-StandardIcon x-svgIcon">
                    <svg viewBox="0 0 20 20" style={{ height: 14, width: 20, fill: 'rgb(148, 154, 155)' }}>
                      <path d="M9.92413793,15.3 L16.5,15.3 C18.2,15.3 19.5,14 19.5,12.3 L19.5,3.1 C19.5,1.4 18.2,0.1 16.5,0.1 L3.5,0.1 C1.8,0.1 0.5,1.4 0.5,3.1 L0.5,12.3 C0.5,14 1.8,15.3 3.5,15.3 L5.8,15.3 L5.8,19.9 L9.92413793,15.3 Z" />
                    </svg>
                  </span>
                </span>
                <span className="-text">Comment</span>
              </span>
              <span className="-facebookAction">
                <span className="-share -icon">
                  <span className="rc-IconBase x-StandardIcon x-svgIcon">
                    <svg viewBox="0 0 20 20" style={{ height: 16, width: 20, fill: 'rgb(148, 154, 155)' }}>
                      <path d="M10.1,12.9439786 L10.1,17.3 C10.1,18.4 10.8,18.7 11.6,17.9 L18.8,11.2 C19.6,10.5 19.6,9.3 18.8,8.5 L11.9,2.1 L11.5,1.7 C10.7,0.9 10.1,1.1 10.1,2.2 L10.1,7.77060157 C7.51159214,8.28822115 2.10422458,10.3763424 0.6,18.8 C0.6,19 0.8,18.3 0.7,18.4 C0.3,19.5 0.6,18.1 3.1,15.3 C3.9,14.4 5.3,13.6 6.5,13.2 C7.15677342,12.9810755 8.77210533,12.8520159 10.1,12.9439786 L10.1,12.9439786 Z" />
                    </svg>
                  </span>
                </span>
                <span className="-text">Share</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FacebookPreview
