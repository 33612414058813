/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import style from './index.module.scss'
import TwitterPreview from './twitter'
import FacebookPreview from './facebook'
import { Post } from './data'

interface IProps {
  posts?: Post[]
}

const Preview: React.FunctionComponent<IProps> = ({ posts }: IProps) => {
  return (
    <div className={style.preview}>
      {posts?.map((post, index) => (
        <div key={post.socialProfile.id} className={style.post}>
          {post.socialProfile.type === 'TWITTER' && <TwitterPreview post={post} />}
          {post.socialProfile.type === 'FACEBOOKPAGE' && <FacebookPreview post={post} />}
        </div>
      ))}
    </div>
  )
}

export default Preview
