/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { all, put, takeEvery } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import settingsApi from '~/services/Settings'
import actions, { setLoading } from './actions'

function* LOAD_SETTINGS({ payload }: AnyAction) {
  yield put(setLoading(true))

  const result = yield settingsApi.getSettings(payload.sessionId, payload.userId, payload.projectId)

  yield put({
    type: actions.SET_STATE,
    payload: {
      settings: result.data,
      loading: false,
    },
  })
}

function* SAVE_SETTINGS({ payload }: AnyAction) {
  yield put(setLoading(true))

  yield settingsApi.saveSettings(payload.sessionId, payload.projectId, payload.content)

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

function* LOAD_STATE() {
  // const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.settings.user?.access_token }))

  yield put(setLoading(true))

  yield put(setLoading(false))
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_STATE, LOAD_STATE),
    takeEvery(actions.LOAD_SETTINGS, LOAD_SETTINGS),
    takeEvery(actions.SAVE_SETTINGS, SAVE_SETTINGS),
  ])
}
