import { DocumentsApi } from '@antegma/accallio-communication-suite-hootsuite-publishing-api-javacript-sdk'
import { Api } from '@antegma/accallio-cloud-react-components/lib/service/api'
import { getEnv } from '@antegma/accallio-cloud-react-components/lib/app/util/env'

type TEnv = {
  CS_HOOTSUITE_PUBLISHING_API_BASE_PATH: string
}

const env = getEnv<TEnv>({
  CS_HOOTSUITE_PUBLISHING_API_BASE_PATH:
    process.env.REACT_APP_CS_HOOTSUITE_PUBLISHING_API_BASE_PATH || 'https://cs-hootsuite-publishing-api.dev.accallio.com',
})

const documentsApi = new DocumentsApi(undefined, env.CS_HOOTSUITE_PUBLISHING_API_BASE_PATH, Api.instance.axiosInstance)

export default documentsApi
