import { Settings } from '@antegma/accallio-communication-suite-hootsuite-publishing-api-javacript-sdk'
import { AnyAction } from 'redux'
import actions from './actions'

export type TLibrariesState = {
  settings?: Settings
  loading: boolean
}
const initialState = {
  settings: undefined,
  loading: false,
}
export default function librariesReducer(state: TLibrariesState = initialState, action: AnyAction): TLibrariesState {
  switch (action.type) {
    case actions.SET_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case actions.GET_STATE:
      return state
    default:
      return state
  }
}
