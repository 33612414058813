// import { EActivityType, ESocialNetworkType } from "Services/api/activity-stream/enum";
const de = {
  'Selected ReportingSuite': 'Ausgewählte ReportingSuite',
  None: 'Keine',
  Submit: 'Abschicken',
  'Please input a API Key!': 'Bitte einen API Key eingeben!',
  'Please input a Secret!': 'Bitte ein Secret eingeben!',
  Settings: 'Einstellungen',
  'Please input the {{value}}!': 'Bitte geben Sie einen Wert für {{value}} ein!',
  'Must be of form "<id>@techacct.adobe.com"': 'Muss der Form "<id>@techacct.adobe.com" entsprechen',
  'Must be of form "<id>@AdobeOrg"': 'Muss der Form "<id>@AdobeOrg" entsprechen',
}
export default de
