/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-danger */

import React from 'react'
import DOMPurify from 'dompurify'
import { TwitterOutlined } from '@ant-design/icons'
import style from './index.module.scss'
import { Post } from '../data'

interface IProps {
  post: Post
}

const TwitterPreview: React.FunctionComponent<IProps> = ({ post }: IProps): JSX.Element => {
  return (
    <div className="message">
      <div className="header">
        <TwitterOutlined style={{ fontSize: '24px', paddingRight: 2, color: '#1DA1F2' }} />
        <span>Twitter</span>
      </div>
      <div className="body">
        <div className="twitterPreview">
          <div className="leftColumn">
            <div className="rc-SocialNetworkAvatar -avatar" style={{ width: 48, height: 48 }}>
              <div className="-imageContainer" style={{ width: 48, height: 48 }}>
                <img alt="antegma" className="avatar x-centered" src={post.socialProfile.avatarUrl} style={{ height: 48 }} />
              </div>
            </div>
          </div>
          <div className="rightColumn">
            <div className="contentHeader">
              <span className="userName" style={{ paddingRight: 5 }}>
                {post.socialProfile.socialNetworkUsername}
              </span>
              <span className="handle" style={{ paddingRight: 5 }}>
                {post.socialProfile.socialNetworkUsername}
              </span>
              <span className="timeStamp">now</span>
            </div>
            <div className="contentBody">
              <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.message.text) }} />
            </div>

            {post.message.mediaUrls?.map((mediaUrl, index) => (
              <div className="-contentMedia">
                <div className="-full">
                  <div className="-image -landscape">
                    <img src={mediaUrl.url} style={{ width: '100%' }} alt="" />
                  </div>
                </div>
              </div>
            ))}

            {post.place && (
              <div className="-contentLocation">
                <span className="-locationPreamble">2:42 AM - 27 Feb 2019 from </span>
                <span className="-locationText">
                  {post.place.name}, {post.place.country}
                </span>
              </div>
            )}

            <div className="contentFooter">
              <span className="twitterIcon">
                <span className="rc-IconBase x-StandardIcon x-svgIcon">
                  <svg viewBox="0 0 20 20" style={{ height: 16, width: 20, fill: 'rgb(170, 184, 194)' }}>
                    <path d="M17.8,10c-1.9-1.9-4.8-1.8-7.5-1.7V3.7c0-0.8-1.4-1.5-3.2-0.1L1,8.3C0.1,9,0.1,10.1,1,10.8l6.2,5 c1.7,1.4,3.1,0.7,3.1-1.5v-3.1c0,0,0,0,0,0c2.3-0.1,4.3-0.2,5.4,0.8c0.6,0.6,1.2,1.2,1.1,3.5c0,0.8,0.6,1.5,1.4,1.6c0,0,0,0,0.1,0 c0.8,0,1.5-0.6,1.5-1.4C20,12.4,18.9,11,17.8,10z" />
                  </svg>
                </span>
              </span>
              <span className="twitterIcon">
                <span className="rc-IconBase x-StandardIcon x-svgIcon">
                  <svg viewBox="0 0 20 20" style={{ height: 16, width: 20, fill: 'rgb(170, 184, 194)' }}>
                    <g>
                      <path d="M10,15.2H6.1v-4.3h2.3c0.6,0,0.9-0.8,0-1.7L6.1,6.6c-0.8-0.8-2-0.8-2.8,0L0.6,9.4C0,10.1,0,10.8,1,10.8h0.5 h1.7v5.8c0,0.8,0.7,1.5,1.5,1.5H10c0.8,0,1.5-0.7,1.5-1.5S10.8,15.2,10,15.2z" />
                      <path d="M19,12.2h-0.5h-1.7V6.3c0-0.8-0.7-1.5-1.5-1.5H10c-0.8,0-1.5,0.7-1.5,1.5S9.2,7.8,10,7.8h3.9v4.3h-2.3 c-0.6,0-0.9,0.8,0,1.7l2.3,2.4c0.8,0.8,2,0.8,2.8,0l2.7-2.8C20,12.9,20,12.2,19,12.2z" />
                    </g>
                  </svg>
                </span>
              </span>
              <span className="twitterIcon">
                <span className="rc-IconBase x-StandardIcon x-svgIcon">
                  <svg viewBox="0 0 20 20" style={{ height: 16, width: 20, fill: 'rgb(170, 184, 194)' }}>
                    <path d="M18.5,5.5C18,4.2,17.2,3.2,16,2.6c-1-0.5-2.2-0.6-3.4-0.1c-1,0.4-1.9,1-2.6,1.9C9.3,3.5,8.4,2.8,7.4,2.5 C6.3,2,5.1,2.1,4,2.6C2.8,3.2,2,4.2,1.5,5.5C1.2,6.6,1,8.4,2.2,10.8c1.2,2.4,3.4,4.2,6.9,7.2l0.4,0.3c0.1,0.1,0.3,0.2,0.5,0.2 s0.3-0.1,0.5-0.2l0.4-0.3c3.5-3,5.7-4.8,6.9-7.2C19,8.4,18.8,6.6,18.5,5.5z" />
                  </svg>
                </span>
              </span>
              <span className="twitterIcon">
                <span className="rc-IconBase x-StandardIcon x-svgIcon">
                  <svg viewBox="0 0 20 20" style={{ height: 16, width: 20, fill: 'rgba(170, 184, 194, 1.0)' }}>
                    <g>
                      <ellipse cx="3.4" cy="10" rx="2.4" ry="2.5" />
                      <ellipse cx="10" cy="10" rx="2.4" ry="2.5" />
                      <ellipse cx="16.6" cy="10" rx="2.4" ry="2.5" />
                    </g>
                  </svg>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TwitterPreview
