import React from 'react'
import { Button } from 'antd'

export interface PhotoEditorProps {
  imageUrl: string
}

export class PhotoEditor extends React.Component<PhotoEditorProps> {
  componentDidMount(): void {
    // this.initEditor()
  }

  /* async initEditor(): void {
    const { imageUrl } = this.props
    const editor = await PhotoEditorSDKUI.init({
      container: '#editor',
      image: imageUrl,
      export: { image: { exportType: ExportFormat.DATA_URL } },
      license:
        '{"api_token":"iGFxzCnlLk-nD1RBe1I9Tg","app_identifiers":["*.accallio.com","localhost"],"available_actions":[],"domains":["https://api.photoeditorsdk.com"],"enterprise_license":false,"expires_at":null,"features":["camera","library","export","customassets","whitelabel","adjustment","filter","text","transform"],"issued_at":1631561693,"minimum_sdk_version":"1.0","owner":"antegma GmbH","platform":"HTML5","products":["pesdk"],"version":"2.4","signature":"S+IFwtJ1DgICre2tkdTuTmrUePZREET4ImWdxbd/eOSajHjCPB8cmxC7NZFLiaq/aulpfzut4oeG+/CJe96C97uc1IASkscsMFwTlp7Zfu7J6g3z+5Ja9m2DEKboiRErh5hbbvZRGtZrEsr3d4rN50ik57KELWz8sUIIWXAqZr8LoFZqAU44XnC6tdHpHESTtlhWMmKF42RepWoWnPuYW0oItluKZyok1/f2N/7+vyu3Zm2CT1UEgI+a7g0gYOLxlX2QX9xd2NUrZqwj91NABk1r2x0LC9dCXnNz++Lm3njd6LJl5tA2Rb6ed+jz5ogR2kMzmGqfAeZAqjdVWsGY9kNCKMtrpcnqee+nVN9Dn+0HMlTnSlHL88obcq9DUXY2Vw8egST4E+0/YJssfc/eg85yvpxOAmOkJpF/GyztQjK1qG2OhJcq4zftk1LRK+qf6hx2qTeEKBIi0dLccBgUrjv8vrsMCWHf8DAOqpVRHdxtvH4oSgrj4h7ImgOSfUdSDxDJWS5OE205c4+8sonvqvjmLmYueoDFCnanW96bVc3YL8BVp/TnQc1m/Uapoozowbsf7ncPLp+XBJ7uKsZgoN9XWfgpd78HEx9DS5W+llCYaSJ6K2kH4x7ZS2lgEsO+/zPNtNZE+L3zxHuYev+U2SmCHsH2m9vzCRyc64qGajY="}',
    })
    console.log('PhotoEditorSDK for Web is ready!')
    editor.on(UIEvent.EXPORT, (imageSrc) => {
      console.log('Exported ', imageSrc)
    })

    return editor;
  } */

  render(): JSX.Element {
    return (
      <div>
        <div style={{ width: '100%', height: 'calc(100% - 60px)', position: 'relative' }}>
          <div id="editor" style={{ width: '100%', height: '100%' }} />
        </div>
        <div style={{ height: 60, width: '100%', backgroundColor: '#ff000' }}>
          <Button>Cancel</Button>
          <Button>Save</Button>
        </div>
      </div>
    )
  }
}
